import { useEffect, useState } from 'react'

import type {
  BurnsStatsApiData,
  BuybacksApiData,
  MarketDataApiData,
  StrapiLastMarketplaceBuybackApiData,
} from '~/api/types'

export const AVERAGE_BLOCK_INCLUDE_SECONDS = 3

export const OTHER_BURNS_ANIMATION_DURATION =
  1000 * AVERAGE_BLOCK_INCLUDE_SECONDS
export const MARKETPLACE_BURNS_ANIMATION_DURATION = 1000

interface UseSimulateStatsProps {
  marketDataApiData: MarketDataApiData
  buybacksApiData: BuybacksApiData
  burnsStatsApiData: BurnsStatsApiData
  strapiLastMarketplaceBuybackApiData: StrapiLastMarketplaceBuybackApiData
}

export interface SimulatedStats {
  burnedToday: number
  burnedWeek: number
  burnedMonth: number
  marketplaceBurns: number
  otherBurns: number
  totalBurned: number
  currentSupply: number
  projectSupply: number
  currentCirculation: number
}

export function useSimulateStats({
  marketDataApiData,
  buybacksApiData,
  burnsStatsApiData,
  strapiLastMarketplaceBuybackApiData,
}: UseSimulateStatsProps): SimulatedStats {
  const {
    initialTotalSupply,
    circulation: currentCirculation,
    burned: totalBurned,
    company: projectSupply,
  } = marketDataApiData

  const { amount: marketplaceBurns } =
    buybacksApiData.buybacks.marketplace.total

  const { cachedDailyAvg, dailyAvg, burnedToday, burnedWeek, burnedMonth } =
    burnsStatsApiData

  const otherBurns = totalBurned - marketplaceBurns

  const {
    amount: lastMarketplaceBurnsValue,
    amountPerSecond: lastMarketplaceBurnsUpdateValue,
  } = strapiLastMarketplaceBuybackApiData

  const otherBurnsUpdateCacheValue =
    cachedDailyAvg * AVERAGE_BLOCK_INCLUDE_SECONDS
  const otherBurnsUpdateValue = dailyAvg * AVERAGE_BLOCK_INCLUDE_SECONDS

  const [otherBurnsSimulated, setOtherBurnsSimulated] = useState({
    burnedToday: burnedToday + otherBurnsUpdateCacheValue,
    burnedWeek: burnedWeek + otherBurnsUpdateCacheValue,
    burnedMonth: burnedMonth + otherBurnsUpdateCacheValue,
    otherBurns: otherBurns + otherBurnsUpdateCacheValue,
  })

  const [marketplaceBurnsSimulated, setMarketplaceBurnsSimulated] = useState(
    marketplaceBurns + lastMarketplaceBurnsValue
  )

  // const [projectSupplySimulated, setProjectSupplySimulated] = useState(
  //   projectSupply - otherBurnsUpdateCacheValue - lastMarketplaceBurnsValue
  // )

  const [currentCirculationSimulated, setCurrentCirculationSimulated] =
    useState(
      currentCirculation -
        otherBurnsUpdateCacheValue -
        lastMarketplaceBurnsValue
    )

  const simulateOtherBurns = () =>
    setOtherBurnsSimulated(previousState => {
      const { burnedToday, burnedWeek, burnedMonth, otherBurns } = previousState

      return {
        burnedToday: burnedToday + otherBurnsUpdateValue,
        burnedWeek: burnedWeek + otherBurnsUpdateValue,
        burnedMonth: burnedMonth + otherBurnsUpdateValue,
        otherBurns: otherBurns + otherBurnsUpdateValue,
      }
    })

  const simulateMarketplaceBurns = () =>
    setMarketplaceBurnsSimulated(previousState => {
      return previousState + lastMarketplaceBurnsUpdateValue
    })

  // const simulateProjectSupply = () =>
  //   setProjectSupplySimulated(previousState => {
  //     return (
  //       previousState - otherBurnsUpdateValue - lastMarketplaceBurnsUpdateValue
  //     )
  //   })

  const simulateCurrentCirculation = () =>
    setCurrentCirculationSimulated(previousState => {
      return (
        previousState - otherBurnsUpdateValue - lastMarketplaceBurnsUpdateValue
      )
    })

  useEffect(() => {
    simulateOtherBurns()
    simulateMarketplaceBurns()
    simulateCurrentCirculation()
    // simulateProjectSupply()

    const otherBurnsInterval = setInterval(
      simulateOtherBurns,
      OTHER_BURNS_ANIMATION_DURATION
    )

    const marketplaceBurnsInterval = setInterval(
      simulateMarketplaceBurns,
      MARKETPLACE_BURNS_ANIMATION_DURATION
    )

    // const projectSupplyInterval = setInterval(
    //   simulateProjectSupply,
    //   MARKETPLACE_BURNS_ANIMATION_DURATION
    // )

    const currentCirculationInterval = setInterval(
      simulateCurrentCirculation,
      OTHER_BURNS_ANIMATION_DURATION
    )

    return () => {
      clearInterval(otherBurnsInterval)
      clearInterval(marketplaceBurnsInterval)
      // clearInterval(projectSupplyInterval)
      clearInterval(currentCirculationInterval)
    }
  }, [])

  const totalBurnedSimulated =
    marketplaceBurnsSimulated + otherBurnsSimulated.otherBurns

  return {
    burnedToday: otherBurnsSimulated.burnedToday,
    burnedWeek: otherBurnsSimulated.burnedWeek,
    burnedMonth: otherBurnsSimulated.burnedMonth,
    marketplaceBurns: marketplaceBurnsSimulated,
    otherBurns: otherBurnsSimulated.otherBurns,
    totalBurned: totalBurnedSimulated,
    currentSupply: initialTotalSupply - totalBurnedSimulated,
    projectSupply /*: projectSupplySimulated*/,
    currentCirculation: currentCirculationSimulated,
  }
}
