import type { FC } from 'react'
import clsx from 'clsx'

import {
  AnimatedNumber,
  ButtonVariant,
  CryptoCurrencyFormatter,
  DataPoint,
  ExplorerButton,
  ExplorerType,
  H3,
} from '~/tenset-components'
import { OTHER_BURNS_ANIMATION_DURATION } from '~/hooks/simulated-stats'
import type { Utils } from '~/tenset-web3'
import { getNetworkExplorer } from '~/tenset-web3'

export interface BurnData {
  label: string
  data: {
    value: number
    formatter?: FC<{ value: number }>
    address?: string
    network?: Utils.Network
    animatedNumberDuration?: number
  }[]
}

interface BuybacksDataSectionProps {
  title?: string
  burnsData: BurnData[]
  wider?: boolean
}

export function BuybacksDataSection({
  title,
  burnsData,
  wider = false,
}: BuybacksDataSectionProps) {
  if (!burnsData || burnsData.length === 0) return null

  return (
    <div className="flex flex-col gap-4">
      {title && <H3 isBold>{title}</H3>}

      <div
        className={clsx(
          'mt-4 grid items-start gap-4 break-words sm:grid-cols-2 md:grid-cols-3',
          wider ? 'items-stretch lg:grid-cols-5' : 'md:max-w-5xl'
        )}
      >
        {burnsData.map(({ label, data }) => (
          <DataPoint label={label} key={label}>
            {data.map(
              (
                {
                  value,
                  formatter = ({ value }) => (
                    <CryptoCurrencyFormatter value={value} precision={[4, 4]} />
                  ),
                  address,
                  network,
                  animatedNumberDuration = OTHER_BURNS_ANIMATION_DURATION,
                },
                index
              ) => (
                <div key={index}>
                  <AnimatedNumber
                    value={value}
                    duration={animatedNumberDuration}
                    formatter={formatter}
                  />

                  {address && network && (
                    <ExplorerButton
                      address={address}
                      explorerUrl={getNetworkExplorer(network)}
                      explorerType={ExplorerType.ADDRESS}
                      variant={ButtonVariant.Link}
                      fullAddress
                      className="mt-5"
                    />
                  )}
                </div>
              )
            )}
          </DataPoint>
        ))}
      </div>
    </div>
  )
}
