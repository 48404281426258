import { Link } from '@remix-run/react'

import { HrefType, H2 } from '~/tenset-components'

type IntrinsicElements = keyof JSX.IntrinsicElements

export interface ShowcaseProps {
  title: string
  image: JSX.Element
  link: {
    type: HrefType
    url: string
  }
}

export default function Showcase({ title, image, link }: ShowcaseProps) {
  const LinkComponent = (
    link.type === HrefType.Internal ? Link : 'a'
  ) as IntrinsicElements
  const linkAttributes =
    link.type === 'internal' ? { to: link.url } : { href: link.url }

  return (
    <div className="flex flex-col gap-8 md:gap-12">
      <H2 isBold>{title}</H2>

      <LinkComponent {...linkAttributes}>{image}</LinkComponent>
    </div>
  )
}
